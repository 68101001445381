import Box from '@mui/material/Box';

import { useEffect } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { dropDownHelperInvestmentType, dropDownHelperParkingSpots, dropDownHelperStatus, dropDownHelperStorage } from './helper';
import { PropertiesSagaActions } from 'store/properties/saga';
import { ClientsAutocompleteListSelector, selectedPropertySelector, SelectedPropertyUnitSelector } from 'store/properties/selector';
import { Controller, useForm } from 'react-hook-form';
import { ClientsSagaActions } from 'store/clients/saga';
import { countriesListSelector } from 'store/clients/selector';
import { countriesListType } from 'store/clients/types';
import { ClientsListDataType, PropertiesDataType } from 'store/properties/types';
import './style.scss';
import { PropertiesReducerAction } from 'store/properties/slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSagaActions } from 'store/users/saga';
import { managersListSelector } from 'store/users/selector';
import { UserDetailsType } from 'store/users/types';
import UploadButton from 'ui-component/UploadButton/UploadButton';
import { emailRegex } from 'utils/helper';

export default function TextMobileStepper() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        unit_name: yup.string().trim().required('Unit Name is required'),
        agent_email: yup.string().matches(emailRegex, 'Agent email is not valid').nullable(),
        agent_phone: yup
            .string()
            .matches(/^[0-9]+$|^$/, 'Agent Phone is not valid')
            .nullable(),
        client_email: yup.string().matches(emailRegex, 'Client email is not valid').nullable(),
        usable_area: yup.number().typeError('Usable area must be number').min(0, 'Usable Area must be positive').nullable(),
        private_gross_area: yup
            .number()
            .typeError('Private Gross Area must be number')
            .min(0, 'private Gross Area must be positive')
            .nullable(),
        total_gross_area: yup.number().typeError('Total Gross Area must be number').min(0, 'Total Gross Area must be positive').nullable(),
        total_area: yup.number().typeError('Total Area must be number').min(0, 'Total Area must be positive').nullable(),
        responsible_technician_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Responsible Technician Name is not valid')
            .nullable(),
        responsible_technician_email: yup.string().matches(emailRegex, 'Responsible Technician Email is not valid').nullable(),
        responsible_technician_phone: yup
            .string()
            .matches(/^[0-9]+$|^$/, 'Responsible Technician Phone is not valid')
            .nullable(),
        fiscal_representative_email: yup.string().matches(emailRegex, 'Fiscal Representative Email is not valid').nullable(),
        fiscal_representative_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Fiscal Representative Name is not valid')
            .nullable(),
        fiscal_representative_phone: yup
            .string()
            .matches(/^[0-9]+$|^$/, 'Fiscal Representative Phone is not valid')
            .nullable(),
        legal_representative_email: yup.string().matches(emailRegex, 'Legal Representative Email is not valid').nullable(),
        legal_representative_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Legal Representative Name is not valid')
            .nullable(),
        legal_representative_phone: yup
            .string()
            .matches(/^[0-9]+$|^$/, 'Legal Representative Phone is not valid')
            .nullable()
    });

    const handleBack = () => {
        navigate(-1);
        dispatch(PropertiesReducerAction.setSelectedPropertyUnit({}));
    };

    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);

    useEffect(() => {
        const getAPIsCall = async () => {
            await dispatch(ClientsSagaActions.getCountriesList());
            await dispatch(UserSagaActions.getUserByManagerRole());
            await dispatch(PropertiesSagaActions.fetchClientsAutoComplete({ page: 1, pageSize: 10 }));
        };
        getAPIsCall();
        id && dispatch(PropertiesSagaActions.fetchPropertyUnitById(id));
    }, [dispatch, id]);

    const propertyDetails: PropertiesDataType = useSelector(selectedPropertySelector);
    const managersList: UserDetailsType[] = useSelector(managersListSelector);
    const clientsList: ClientsListDataType[] = useSelector(ClientsAutocompleteListSelector);

    const countriesList = useSelector(countriesListSelector);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        control,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: selectedPropertyUnit && selectedPropertyUnit
    });

    const onSubmit = async (values: { [key: string]: any }) => {
        const data = {
            ...values,
            usable_area: parseInt(values?.usable_area),
            private_gross_area: parseInt(values?.private_gross_area),
            total_gross_area: parseInt(values?.total_gross_area),
            total_area: parseInt(values?.total_area),
            storage: values?.storage ?? dropDownHelperStorage[0]?.value,
            status: values.status,
            parking_spaces: values?.parking_spaces || dropDownHelperParkingSpots[0]?.value,
            properties_id: values?.properties_id || propertyDetails?._id,
            agent_name: values?.agent_name || 'Michel Thiran',
            client_name: values?.client_name || clientsList[0]?.name
        };
        if (id) {
            await dispatch(PropertiesSagaActions.editPropertyUnit(data));
        } else {
            await dispatch(PropertiesSagaActions.addPropertyUnit(data));
        }
        navigate(-1);
    };

    const propertySectionsList = [
        { id: 1, label: propertyDetails?.property_section_1 },
        { id: 2, label: propertyDetails?.property_section_2 },
        { id: 3, label: propertyDetails?.property_section_3 },
        { id: 4, label: propertyDetails?.property_section_4 }
    ];

    const selectedClient = clientsList?.find((client) => client?.name === (watch('client_name') ?? selectedPropertyUnit?.client_name));

    console.log(selectedClient, 'selected client');
    useEffect(() => {
        if (selectedPropertyUnit) {
            reset({
                ...selectedPropertyUnit,
                building: selectedPropertyUnit?.building || propertySectionsList[0]?.label,
                manager: selectedPropertyUnit?.manager ?? managersList[0]?._id,
                storage: selectedPropertyUnit?.storage,
                purpose: selectedPropertyUnit?.purpose,
                agent_nationality: selectedPropertyUnit.agent_nationality ?? countriesList[0]?.name,
                client_nationality: selectedClient?.country ?? countriesList[0]?.name,
                client_phone: selectedClient?.phone,
                client_email: selectedClient?.email,
                client_name: selectedClient?.name
            });
        }
    }, [selectedPropertyUnit, selectedClient]);

    return (
        <form>
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid item marginBottom={'20px'} xs={12} className="media-uploads">
                            <Grid item xs={4} className="media-upload">
                                <UploadButton setValue={setValue} field={'image'} register={register} watch={watch} />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('unit_name') }}
                                helperText={errors?.unit_name?.message?.toString()}
                                error={!!errors?.unit_name}
                                required
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Unit Name"
                                {...register('unit_name')}
                            />
                        </Grid>
                        <Grid item className="input-property-forms" paddingRight={{ xs: '0px', md: '10px' }} xs={12} md={6} lg={2.4}>
                            <FormControl fullWidth>
                                <InputLabel id="building-select">Building</InputLabel>
                                <Controller
                                    control={control}
                                    name="building"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Building"
                                                defaultValue={propertySectionsList[0]?.label}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('building')}
                                            >
                                                {propertySectionsList?.map((option) => (
                                                    <MenuItem value={option.label}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {/* {option.icon} */}
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="purpose-select">Purpose</InputLabel>
                                <Controller
                                    control={control}
                                    name="purpose"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Purpose"
                                                defaultValue={dropDownHelperInvestmentType[0]?.value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('purpose')}
                                            >
                                                {dropDownHelperInvestmentType.map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="manager-select">Manager</InputLabel>
                                <Controller
                                    control={control}
                                    name="manager"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Manager"
                                                ref={ref}
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={managersList[0]?._id}
                                            >
                                                {managersList?.map((option) => (
                                                    <MenuItem value={option._id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="status-select">Status</InputLabel>
                                <Controller
                                    control={control}
                                    name="status"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Status"
                                                ref={ref}
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={dropDownHelperStatus[0]?.value}
                                            >
                                                {dropDownHelperStatus.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agency-select">Agency</InputLabel>
                                <Controller
                                    control={control}
                                    name="agency"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                label="Agency"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value || 'agency'}
                                            >
                                                {[{ label: 'Agency', value: 'agency' }].map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agent-select">Agent</InputLabel>
                                <Controller
                                    control={control}
                                    name="agent_name"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                label="Agent"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                            >
                                                {[{ label: 'Michel Thiran', value: 'Michel Thiran' }].map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_phone') }}
                                fullWidth
                                helperText={errors?.agent_phone?.message?.toString()}
                                error={!!errors?.agent_phone}
                                label="Agent Phone"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_email') }}
                                fullWidth
                                helperText={errors?.agent_email?.message?.toString()}
                                error={!!errors?.agent_email}
                                label="Agent email"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agent-nationality-select">Agent Nationality</InputLabel>
                                <Controller
                                    control={control}
                                    name="agent_nationality"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                label="Agent Nationality"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={countriesList[0]?.name}
                                            >
                                                {countriesList?.map((option: countriesListType) => (
                                                    <MenuItem key={option.code} value={option?.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="client-select">Client</InputLabel>
                                <Controller
                                    control={control}
                                    name="client_name"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Client"
                                                ref={ref}
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                            >
                                                {clientsList?.map((option: ClientsListDataType) => (
                                                    <MenuItem value={option.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_phone') }}
                                fullWidth
                                helperText={errors?.client_phone?.message?.toString()}
                                error={!!errors?.client_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Client Phone"
                                {...register('client_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_email') }}
                                fullWidth
                                helperText={errors?.client_email?.message?.toString()}
                                error={!!errors?.client_email}
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                    '.MuiInputBase-input': { fontWeight: '600' }
                                }}
                                label="Client Email"
                                {...register('client_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="client-nationality-select">Client Nationality</InputLabel>
                                <Controller
                                    control={control}
                                    name="client_nationality"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                label="Client Nationality"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                            >
                                                {countriesList?.map((option: countriesListType) => (
                                                    <MenuItem key={option.code} value={option?.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_name') }}
                                fullWidth
                                helperText={errors?.responsible_technician_name?.message?.toString()}
                                error={!!errors?.responsible_technician_name}
                                label="Responsible Technician"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('responsible_technician_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_phone') }}
                                fullWidth
                                helperText={errors?.responsible_technician_phone?.message?.toString()}
                                error={!!errors?.responsible_technician_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Responsible Technician Phone"
                                {...register('responsible_technician_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_email') }}
                                fullWidth
                                helperText={errors?.responsible_technician_email?.message?.toString()}
                                error={!!errors?.responsible_technician_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Responsible Technician Email"
                                {...register('responsible_technician_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_name') }}
                                fullWidth
                                helperText={errors?.fiscal_representative_name?.message?.toString()}
                                error={!!errors?.fiscal_representative_name}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative"
                                {...register('fiscal_representative_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_phone') }}
                                fullWidth
                                helperText={errors?.fiscal_representative_phone?.message?.toString()}
                                error={!!errors?.fiscal_representative_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Technician Phone"
                                {...register('fiscal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_email') }}
                                fullWidth
                                helperText={errors?.fiscal_representative_email?.message?.toString()}
                                error={!!errors?.fiscal_representative_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative Email"
                                {...register('fiscal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_name') }}
                                fullWidth
                                helperText={errors?.legal_representative_name?.message?.toString()}
                                error={!!errors?.legal_representative_name}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative"
                                {...register('legal_representative_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_phone') }}
                                fullWidth
                                helperText={errors?.legal_representative_phone?.message?.toString()}
                                error={!!errors?.legal_representative_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Phone"
                                {...register('legal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_email') }}
                                fullWidth
                                helperText={errors?.legal_representative_email?.message?.toString()}
                                error={!!errors?.legal_representative_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Email"
                                {...register('legal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink: !!watch('usable_area') || watch('usable_area') === 0 || Boolean(errors?.usable_area)
                                }}
                                fullWidth
                                helperText={errors?.usable_area?.message?.toString()}
                                error={!!errors?.usable_area}
                                defaultValue={0}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                type="number"
                                label="Usable Area"
                                {...register('usable_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink:
                                        !!watch('private_gross_area') ||
                                        watch('private_gross_area') === 0 ||
                                        Boolean(errors?.private_gross_area)
                                }}
                                fullWidth
                                helperText={errors?.private_gross_area?.message?.toString()}
                                error={!!errors?.private_gross_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Private Gross Area"
                                {...register('private_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink:
                                        !!watch('total_gross_area') || watch('total_gross_area') === 0 || Boolean(errors?.total_gross_area)
                                }}
                                fullWidth
                                helperText={errors?.total_gross_area?.message?.toString()}
                                error={!!errors?.total_gross_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Total Gross Area"
                                {...register('total_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink: !!watch('total_area') || watch('total_area') === 0 || Boolean(errors?.total_area)
                                }}
                                fullWidth
                                helperText={errors?.total_area?.message?.toString()}
                                error={!!errors?.total_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Area Total"
                                {...register('total_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('m2_value') }}
                                fullWidth
                                label="M2 Value"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('m2_value')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="parking-spaces-select">Parking Spaces</InputLabel>
                                <Controller
                                    control={control}
                                    name="parking_spaces"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                ref={ref}
                                                label="Parking Spaces"
                                                onBlur={onBlur}
                                                value={value}
                                                defaultValue={dropDownHelperParkingSpots[0]?.value}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            >
                                                {dropDownHelperParkingSpots.map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="storage-select">Storage</InputLabel>
                                <Controller
                                    control={control}
                                    name="storage"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            label="storage"
                                            ref={ref}
                                            onBlur={onBlur}
                                            value={value}
                                            defaultValue={dropDownHelperStorage[0]?.value}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        >
                                            {dropDownHelperStorage.map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                        <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                            Back to Property
                        </Button>
                        <Button
                            variant="outlined"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </form>
    );
}
