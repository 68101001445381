import axiosServices from 'utils/axios';

export const GetPropertyUnitsDetailsById = async ({ id, query }: { id: string; query: string }) => {
    try {
        const response = await axiosServices.get(`/units/properties-units/${id}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertyClientsDetailsById = async ({userId, query }: { userId: string, query: string }) => {
    try {
        const response = await axiosServices.get(`/properties/clients/${userId}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertyUpdatesDetailsById = async ({ userId, query }: { userId: string; query: string }) => {
    try {
        const response = await axiosServices.get(`/properties/updates/${userId}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertyDocumentsById = async (userId: string) => {
    try {
        const response = await axiosServices.get(`/document/user-documents/${userId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertyGalleryDataById = async (userId: string) => {
    try {
        const response = await axiosServices.get(`/document/property-gallery/${userId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetPropertyUnitById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/units/units-details/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const AddNewPropertyUnit = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/units/saveUnits', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const EditPropertyUnit = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/units/update-units-details/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetUnitsFinancialsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/units-financial/unit-financials/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const EditUnitsFinancials = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/units-financial/update/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetUnitsModificationsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/unit-modification/unit-modification/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const AddUnitModification = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/unit-modification/save', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const EditUnitModification = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/unit-modification/update/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const DeleteUnitModification = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/unit-modification/delete/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getPropertyDetailDocuments = async (payload: { [key: string]: string }) => {
    try {
        const { propertyId, unit_ids } = payload;
        const response = await axiosServices.get(`/document/property-gallery/${propertyId}?unitId=${unit_ids || null}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createPropertyDocument = async (payload: any) => {
    try {
        const response = await axiosServices.post(`/document/saveDocument`, payload);
        return response;
    } catch (error) {
        return error;
    }
};

export const deletePropertyDocument = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/document/delete-document/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const CreateCalculationsForUnit = async (payload: any) => {
    try {
        const response = await axiosServices.post('/units/calculations', payload);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetFinancialsByUnitId = async (payload: string) => {
    try {
        const response = await axiosServices.get(`/units-financial/details/${payload}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetClientsAutocomplete = async (payload: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post(`/client/searchClient/list`, payload);
        return response;
    } catch (error) {
        return error;
    }
};
